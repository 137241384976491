<!--
 * @Author: zhouyajuan
 * @Date: 2020-10-10 09:19:20
 * @LastEditTime: 2021-07-22 17:02:49
 * @LastEditors: yangliao
 * @Description: 批量开票
 * @FilePath: /netHallOfficialAccounts/src/views/Invoice/index.vue
-->
<template>
  <div class="invoice-page">
    <div class="invoice-header" v-if="isBind">
      <van-tabs @click="handleClick" color="#1677FF" v-model="currentTab">
        <!-- 已开发票 -->
        <van-tab title="已开发票" name="1">
          <div class="invoice-select">
            <!-- :close-on-click-overlay="false" -->
            <van-dropdown-menu active-color="#1677FF">
              <van-dropdown-item :title="accountScreen" ref="itemTwo">
                <div class="content">
                  <van-radio-group v-model="result">
                    <van-radio
                      v-for="(item, index) in topDatainfo"
                      :key="index"
                      :name="item.wuserNo"
                      @click="getRadioValue(item.wuserNo)"
                    >
                      <div class="right-txt">
                        <div class="first-li">
                          <span
                            >{{ item.wuserName }} 户号: {{ item.wuserNo }}</span
                          >
                        </div>
                        <div class="center-li">
                          <p>
                            <em
                              v-if="item.labelName && item.labelName.length > 0"
                              >{{ item.labelName }}</em
                            >
                            {{ item.wuserAddress }}
                          </p>
                        </div>
                      </div>
                    </van-radio>
                    <div class="bottom-content">
                      <van-radio name="all" @click="getRadioValue('all')"
                        >全部账户</van-radio
                      >
                    </div>
                  </van-radio-group>
                </div>
              </van-dropdown-item>
              <van-dropdown-item :title="billYear" ref="yearTwo">
                <drop-down-selection
                  :currentValue="currentIndex"
                  :dataValArr="yearList"
                  @getSelectValue="getSelectValueInfo"
                />
              </van-dropdown-item>
            </van-dropdown-menu>
            <!-- 已开发票状态 -->
            <div class="invoice-setting">
              <span></span>
              <!-- <span
                @click="isClickRightFlag && getMail(1)"
                :class="isClickMailBtnTwo ? 'current' : ''"
                >{{ mailtxtTwo }}</span
              > -->
            </div>
          </div>

          <!-- BB已开发票 -->
          <van-pull-refresh
            v-model="refreshing"
            @refresh="onRefresh"
            :disabled="true"
          >
            <van-list
              v-if="isDataFlag"
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="selectInvoiced"
            >
              <div class="invoice-content">
                <ul>
                  <li
                    v-for="(item, index) in invoicedData"
                    :key="index"
                    :class="
                      item.isChecked ? 'invoice-li currentLi' : 'invoice-li'
                    "
                    @click="selectCurrentLi(index, 1)"
                  >
                    <div class="first-li">
                      <span>发票金额：¥{{ item.invoiceAmount }}</span>
                    </div>
                    <div class="name-li">
                      <span>
                        {{ item.clientName }}「{{ item.clientCode }}-{{
                          item.address
                        }}」</span
                      >
                    </div>
                    <div class="last-li">
                      <span>{{ item.ticketTime }}开票</span>
                      <van-button
                        type="default"
                        size="small"
                        :disabled="!item.pdfUrl || item.pdfUrl == ''"
                        @click="seeInvoice(item)"
                        v-if="!isClickMailBtnTwo"
                      >
                        {{
                          !item.pdfUrl || item.pdfUrl == ""
                            ? "开票中"
                            : "查看发票"
                        }}
                      </van-button>
                    </div>
                    <div class="triangle" v-if="item.isChecked">
                      <span><van-icon name="success"/></span>
                    </div>
                  </li>
                </ul>
                <!-- 底部选择总数 -->
                <div class="invoice-bottom" v-if="isClickMailBtnTwo">
                  <div class="content">
                    <em>已选{{ totalLi }}笔</em>
                    <div class="btn">
                      <van-button
                        type="default"
                        size="small"
                        class="cancelBtn"
                        @click="cancelContent(1)"
                      >
                        取消
                      </van-button>
                      <van-button
                        type="default"
                        size="small"
                        class="invoiceBtn"
                        @click="showSendEmail"
                        :disabled="!totalLi > 0"
                      >
                        发送到邮箱
                      </van-button>
                    </div>
                  </div>
                </div>
              </div>
            </van-list>
            <no-Data v-else />
          </van-pull-refresh>
        </van-tab>
        <!-- 未开发票 -->
        <van-tab title="未开发票" name="0">
          <div class="invoice-select">
            <!-- :close-on-click-overlay="false" -->
            <van-dropdown-menu active-color="#1677FF">
              <van-dropdown-item :title="accountScreen" ref="itemOne">
                <div class="content">
                  <van-radio-group v-model="result">
                    <van-radio
                      v-for="(item, index) in topDatainfo"
                      :key="index"
                      :name="item.wuserNo"
                      @click="getRadioValue(item.wuserNo)"
                    >
                      <div class="right-txt">
                        <div class="first-li">
                          <span
                            >{{ item.wuserName }} 户号: {{ item.wuserNo }}</span
                          >
                        </div>
                        <div class="center-li van-multi-ellipsis--l2">
                          <p>
                            <em
                              v-if="item.labelName && item.labelName.length > 0"
                              >{{ item.labelName }}</em
                            >{{ item.wuserAddress }}
                          </p>
                        </div>
                      </div>
                    </van-radio>
                    <div class="bottom-content">
                      <van-radio name="all" @click="getRadioValue('all')"
                        >全部账户</van-radio
                      >
                    </div>
                  </van-radio-group>
                </div>
              </van-dropdown-item>
              <!-- 年份 -->
              <van-dropdown-item :title="billYear" ref="yearOne">
                <drop-down-selection
                  :currentValue="currentIndex"
                  :dataValArr="yearList"
                  @getSelectValue="getSelectValueInfo"
                />
              </van-dropdown-item>
            </van-dropdown-menu>
            <div class="invoice-setting">
              <span></span>
              <span
                @click="isClickLeftFlag && getMail(0)"
                :class="isClickMailBtnOne ? 'current' : ''"
                >{{ mailtxtOne }}</span
              >
            </div>
          </div>

          <!-- AA未开发票 -->
          <van-pull-refresh
            v-model="refreshing"
            @refresh="onRefresh"
            :disabled="true"
          >
            <!-- v-if="notInvoicedData !== [] && notInvoicedData.length > 0" -->
            <van-list
              v-model="loading"
              v-show="notInvoicedData.length > 0"
              :finished="finished"
              finished-text="没有更多了"
              @load="selectNotInvoiced"
            >
              <div class="invoice-content">
                <ul>
                  <li
                    v-for="(item, index) in notInvoicedData"
                    :key="index"
                    :class="
                      item.isChecked ? 'invoice-li currentLi' : 'invoice-li'
                    "
                    @click="selectCurrentLi(index, 0)"
                  >
                    <div class="first-li">
                      <span>销帐金额：¥{{ item.cciFact }}</span>
                    </div>
                    <div class="name-li">
                      <span>
                        {{ item.clientName }}「{{ item.clientCode }}-{{
                          item.address
                        }}」</span
                      >
                    </div>
                    <div v-if="item.cciRealPayKindName" class="name-li">
                      <span> 缴费方式：{{ item.cciRealPayKindName }}</span>
                    </div>
                    <div class="last-li">
                      <span v-if="item.cciChargeTime"
                        >销帐时间：{{ item.cciChargeTime }}</span
                      >
                      <!-- <van-button
                        type="default"
                        size="small"
                        @click="getInvoiceInfo($event, index)"
                        v-if="!isClickMailBtnOne"
                      >
                        {{ item.buttonContent }}
                      </van-button> -->
                      <van-button
                        type="default"
                        size="small"
                        @click="gotoDetail(item)"
                        v-if="!isClickMailBtnOne"
                      >
                        申请开票
                      </van-button>
                    </div>
                    <div class="triangle" v-if="item.isChecked">
                      <span><van-icon name="success"/></span>
                    </div>
                  </li>
                </ul>
                <!-- 底部选择总数 -->
                <div class="invoice-bottom" v-if="isClickMailBtnOne">
                  <div class="content">
                    <em>已选{{ totalLi }}笔</em>
                    <div class="btn">
                      <van-button
                        type="default"
                        size="small"
                        class="cancelBtn"
                        @click="cancelContent(0)"
                      >
                        取消
                      </van-button>
                      <van-button
                        type="default"
                        size="small"
                        class="invoiceBtn"
                        @click="getAllInvoice"
                        :disabled="!totalLi > 0"
                      >
                        批量开票
                      </van-button>
                    </div>
                  </div>
                </div>
              </div>
            </van-list>
            <no-Data v-show="notInvoicedData.length == 0" />
          </van-pull-refresh>
        </van-tab>
      </van-tabs>
    </div>
    <no-data v-else />
    <!-- 发票成功-弹出框 -->
    <invoice-popup :showFlag="show" @clickPopup="closePopup" />
    <!-- 发送到邮箱-弹框 -->
    <van-popup
      v-model="showEmail"
      round
      :closeable="false"
      position="bottom"
      :style="{ height: '60%' }"
    >
      <div class="popup-content">
        <div class="title">
          <p>发送到邮箱</p>
        </div>
        <div class="content">
          <van-field
            v-model="email"
            placeholder="请输入邮箱"
            @input="getEamilList"
            clearable
          />
          <div class="email-list" v-if="showEmailList">
            <ul>
              <li
                v-for="(item, index) in listContent"
                :key="index"
                @click="getInputValue(item)"
              >
                <span>{{ item }}</span>
              </li>
            </ul>
          </div>
          <div class="btn">
            <van-button
              type="default"
              class="cancelBtn"
              @click="showEmail = false"
            >
              取消
            </van-button>
            <van-button type="info" class="sendBtn" @click="sendEmail"
              >发送</van-button
            >
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { isEmail } from "@/utils/validator";
import DropDownSelection from "@/components/dropDownSelection";
import InvoicePopup from "./module/invoicePopup";
import { formatTime } from "@/utils/tools";
import store from "@/store";
import serverconfig from "@/api/server.config";
let HALL_WECHAT_API = serverconfig.API;
import {
  // selectNotInvoiced,
  selectReceivedRecords,
  selectInvoiced,
  selectAccount,
  getInvoice,
  invoice,
  sendEmail,
  findRelevantEmail,
  sendInvoice,
  invoicePreview,
  getUrl,
} from "../../api/home";
import { Toast } from "vant";
import NoData from "@/components/noData.vue";
import { debounce } from "lodash-es";
export default {
  name: "InvoiceList",
  components: {
    DropDownSelection,
    InvoicePopup,
    NoData,
  },
  data() {
    return {
      show: false,
      showEmail: false,
      currentMounth: "",
      checked: true,
      notInvoicedData: [], // 未开发票
      invoicedData: [], // 已开发票
      topDatainfo: [], // 账单筛选
      yearList: [],
      accountList: [], // 账户筛选所选中的户号
      accountListAll: [], // 所有账户
      currentIndex: 0,
      totalLi: 0,
      accountScreen: "账户筛选",
      billYear: "年份",
      result: "all", // 账户筛选勾选状态--
      mailtxtOne: "批量开票",
      mailtxtTwo: "批量发送邮箱",
      currentTab: "1",
      isClickMailBtnOne: false,
      isClickMailBtnTwo: false,
      email: "", // email集合
      listContent: [],
      showEmailList: true,
      // 分页
      refreshing: false,
      loading: false,
      finished: false,
      dataTotal: 0,
      currentPage: 1,
      size: 10,
      isBind: store.getters.homeInfo && store.getters.homeInfo.isBind,
      isDataFlag: true,
      isClickLeftFlag: true,
      isClickRightFlag: true,
    };
  },
  mounted() {
    this.getYear();
    this.init();
  },
  methods: {
    async init() {
      if (store.getters.homeInfo.isBind) {
        this.selectAccount();
      }
      if (this.$route.query && this.$route.query.wuserNo) {
        this.accountList = [this.$route.query.wuserNo];
        this.accountScreen = this.$route.query.wuserNo;
        this.result = this.$route.query.wuserNo;
      }
    },
    // AA未开发票数据
    async selectNotInvoiced() {
      let billYear =
        this.billYear === "年份"
          ? new Date().getFullYear() + ""
          : this.billYear.substring(0, this.billYear.lastIndexOf("年"));
      const arg = {
        year: billYear,
        clientCodes: this.accountList,
        page: {
          current: this.currentPage,
          size: this.size,
        },
        isPrint: "N",
      };
      const { status, resultData } = await selectReceivedRecords(arg);
      if (status === "complete") {
        // 处理按钮
        if (resultData && resultData.records.length > 0) {
          resultData.records.map((item) => {
            // item.billTime = item.billTime.split(' ')[0];
            item.billTime = formatTime(item.cciChargeTime);
            item.buttonContent = "我要开票";
          });

          this.dataTotal = resultData.total;
          this.notInvoicedData = this.notInvoicedData.concat(
            resultData.records
          );
          console.log(11, this.notInvoicedData);
          this.currentPage++;
          this.isDataFlag = true;
          // 没有更多数据
          if (this.notInvoicedData.length >= Number(this.dataTotal)) {
            this.finished = true;
          } else {
            this.finished = false;
          }
        } else {
          this.notInvoicedData = [];
          this.finished = true;
          this.isDataFlag = false; // 没有数据
        }
      } else {
        this.finished = true;
      }
      this.loading = false;
    },
    // 已开发票数据
    async selectInvoiced() {
      let billYear =
        this.billYear === "年份"
          ? new Date().getFullYear() + ""
          : this.billYear.substring(0, this.billYear.lastIndexOf("年"));
      const arg = {
        year: billYear,
        clientCodes: this.accountList,
        page: {
          current: this.currentPage,
          size: this.size,
        },
        isPrint: "Y",
      };
      const { status, resultData } = await selectReceivedRecords(arg);
      if (status === "complete") {
        if (resultData && resultData && resultData.records.length > 0) {
          // 分页
          this.dataTotal = resultData.total;
          this.invoicedData = this.invoicedData.concat(resultData.records);
          this.currentPage++;
          this.isDataFlag = true;
          // // 没有更多数据
          if (this.invoicedData.length >= this.dataTotal) {
            this.finished = true;
          } else {
            this.finished = false;
          }
        } else {
          this.invoicedData = [];
          this.finished = true;
          this.isDataFlag = false;
        }
      } else {
        this.finished = true;
      }
      this.loading = false;
    },
    // 账户筛选数据
    async selectAccount() {
      const { status, resultData } = await selectAccount();
      if (status === "complete") {
        this.topDatainfo = resultData;
        let tempAccount = [];
        let indexList = [];
        resultData &&
          resultData.map((item, index) => {
            tempAccount.push(item.wuserNo);
            if (item.selected) {
              indexList.push(index);
            }
          });
        this.accountListAll = tempAccount;
      }
    },
    // tab切换
    handleClick(name, title) {
      // 切换的时候，默认账户筛选 全部勾选状态
      // let indexList = [];
      // this.result = 'all';
      // this.accountScreen = '账户筛选';
      // this.accountList = [];
      // this.billYear = '年份';
      // this.currentIndex = 0; // 年份 默认选择第一个0
      this.currentPage = 1; // 分页
      this.totalLi = 0; // 总条数
      this.mailtxtOne = "批量开票";
      this.mailtxtTwo = "批量发送邮箱";
      this.isClickMailBtnOne = false;
      this.isClickLeftFlag = true;
      this.isClickMailBtnTwo = false;
      if (name === "1") {
        this.invoicedData = [];
        this.selectInvoiced();
      } else {
        this.notInvoicedData = [];
        this.selectNotInvoiced();
      }
      this.currentTab = name;
      // this.finished = false;
    },
    // 我要开票 + 批量发送邮箱
    getMail(msg) {
      if (msg === 0) {
        this.mailtxtOne =
          this.mailtxtOne === "批量开票" ? "请选择要开发票的账单" : "批量开票";
        this.isClickMailBtnOne = !this.isClickMailBtnOne;
      } else {
        this.mailtxtTwo =
          this.mailtxtTwo === "批量发送邮箱"
            ? "选择要发送到邮箱发票"
            : "批量发送邮箱";
        this.isClickMailBtnTwo = !this.isClickMailBtnTwo;
      }
    },
    // 选择多条开票
    selectCurrentLi(index, key) {
      if (key === 0 && this.isClickMailBtnOne) {
        this.notInvoicedData[index].isChecked = !this.notInvoicedData[index]
          .isChecked;
        let i = 0;
        this.notInvoicedData.map((item) => {
          if (item.isChecked) {
            i++;
          }
        });
        this.isClickLeftFlag = false;
        // 已勾选的条数
        this.totalLi = i;
        if (this.totalLi === 0) {
          this.mailtxtOne = "批量开票";
          this.isClickMailBtnOne = !this.isClickMailBtnOne;
          this.isClickLeftFlag = true;
        }
      } else if (key === 1 && this.isClickMailBtnTwo) {
        this.invoicedData[index].isChecked = !this.invoicedData[index]
          .isChecked;
        let i = 0;
        this.invoicedData.map((item) => {
          if (item.isChecked) {
            i++;
          }
        });
        this.isClickRightFlag = false;
        // 已勾选的条数
        this.totalLi = i;
        if (this.totalLi === 0) {
          this.mailtxtTwo = "批量发送邮箱";
          this.isClickMailBtnTwo = !this.isClickMailBtnTwo;
          this.isClickRightFlag = true;
        }
      }
      // this.currentTab = name;
    },
    // 已缴费账单详情
    // gotoPage(item, tabIndex) {
    //   console.log('跳转页面--', item);
    //   this.$router.push({
    //     name: 'FeeDetails',
    //     path: '/QueryPay/FeeDetails',
    //     query: {
    //       name: 'invoice',
    //       tabIndex,
    //       ...item
    //     }
    //   });
    // },
    // 账户筛选---确定
    sureContent() {
      this.$refs.itemOne.toggle();
      this.accountScreen = "账户筛选";

      let tempAccount = [];
      let NotInvoiceWuserNoList = [];
      this.topDatainfo.map((item, index) => {
        if (item.selected) {
          tempAccount.push(item.wuserNo);
          NotInvoiceWuserNoList.push(item.wuserNo);
        }
      });

      // 如果全部选中：则显示 账户筛选
      if (this.topDatainfo.some((item) => item.selected)) {
        this.accountScreen = NotInvoiceWuserNoList.join(",");
      }

      this.accountList = tempAccount.length === 0 ? [""] : tempAccount;
      this.currentPage = 1;
    },
    // 单选按钮事件
    getRadioValue(value) {
      if (value !== "all") {
        this.accountScreen = value;
        this.accountList = [];
        this.accountList.push(value);
      } else if (value === "all") {
        this.accountScreen = "全部账户";
        this.accountList = [];
      }
      // 有条件的筛选
      this.currentPage = 1;
      this.totalLi = 0;
      if (this.currentTab === "0") {
        // 已开发票
        this.notInvoicedData = [];
        this.selectNotInvoiced();
        this.$refs.itemOne.toggle();
      } else {
        // 已开发票
        this.invoicedData = [];
        this.selectInvoiced();
        this.$refs.itemTwo.toggle();
      }
    },
    // 取消事件
    cancelContent(key) {
      if (key === 0) {
        this.notInvoicedData.map((item) => {
          item.isChecked = false;
        });
        this.totalLi = 0;
        this.mailtxtOne = "批量开票";
        this.isClickMailBtnOne = !this.isClickMailBtnOne;
        this.isClickLeftFlag = true;
      } else {
        this.invoicedData.map((item) => {
          item.isChecked = false;
        });
        this.totalLi = 0;
        this.mailtxtTwo = "批量发送邮箱";
        this.isClickMailBtnTwo = !this.isClickMailBtnTwo;
        this.isClickRightFlag = true;
      }
    },
    // AA未开发票---我要开票【每个卡片的按钮】
    async getInvoiceInfo(e, index) {
      e.stopPropagation();
      this.notInvoicedData[index].buttonContent = "开票中...";
      const arg = [
        {
          custRealId: this.notInvoicedData[index].custRealId,
          wuserNo: this.notInvoicedData[index].wuserNo,
          electronicInvoicesId: this.notInvoicedData[index]
            .electronicInvoicesId,
        },
      ];
      const { status, resultData } = await invoice(arg);
      if (status === "complete") {
        this.show = true;
      }
    },
    // 未开发票---批量开票
    async getAllInvoice() {
      let argArray = [];
      this.notInvoicedData &&
        this.notInvoicedData.map((item) => {
          if (item.isChecked) {
            let obj = {
              billType: "5",
              cciBatch: item.cciBatch,
              clientCode: item.clientCode,
              issuanceType: "0",
            };
            argArray.push(obj);
          }
        });
      const { status } = await invoicePreview(argArray);
      if (status === "complete") {
        // 批量开票成功后，显示发票成功页面
        this.show = true;
        this.isClickLeftFlag = true;
      }
    },
    // 发票弹框--关闭成功页面
    closePopup(value) {
      this.notInvoicedData = [];
      this.currentPage = 1;
      this.selectNotInvoiced();
      this.show = false;
    },

    async seeInvoice(item) {
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = getUrl(item.pdfUrl);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // this.$router.push({
      //   name: 'pdf',
      //   query: { url: item.pdfUrl},
      // });
    },
    // 已开发票 - 查看
    gotoDetail: debounce(async function(item) {
      let obj = {
        billType: "5",
        cciBatch: item.cciBatch,
        clientCode: item.clientCode,
        issuanceType: "0",
      };
      let argArray = [];
      argArray.push(obj);
      const { status } = await invoicePreview(argArray);
      if (status === "complete") {
        // 批量开票成功后，显示发票成功页面
        this.show = true;
        this.isClickLeftFlag = true;
      }
    }, 1000),
    // 获取邮箱列表
    getEamilList(event) {
      this.listContent = [];
      if (event.indexOf("@qq") >= 0) {
        this.listContent.push(
          `${event}@163.com`,
          `${event}@126.com`,
          `${event}@sohu.com`
        );
      } else if (event.indexOf("@163") >= 0) {
        this.listContent.push(
          `${event}@qq.com`,
          `${event}@126.com`,
          `${event}@sohu.com`
        );
      } else if (event.indexOf("@126") >= 0) {
        this.listContent.push(
          `${event}@qq.com`,
          `${event}@163.com`,
          `${event}@sohu.com`
        );
      } else if (event.indexOf("@sohu") >= 0) {
        this.listContent.push(
          `${event}@qq.com`,
          `${event}@163.com`,
          `${event}@126.com`
        );
      } else if (event.indexOf("@") >= 0) {
        this.listContent = [];
      } else {
        this.listContent.push(
          `${event}@qq.com`,
          `${event}@163.com`,
          `${event}@126.com`,
          `${event}@sohu.com`
        );
      }
      this.showEmailList = true;
    },
    // 点击列表获取当前选中的邮箱
    getInputValue(value) {
      this.email = value;
      this.showEmailList = false;
    },
    // 已开发票 - 批量发送邮箱
    async showSendEmail() {
      // 获取邮箱值内容
      let wuserNoList = [];
      this.invoicedData.map((item) => {
        if (item.isChecked) {
          wuserNoList.push(item.clientCode);
        }
      });
      let arg = { wuserNoList };

      const { status, resultData } = await findRelevantEmail(arg);
      if (status === "complete") {
        this.email = resultData;
        this.listContent = [];
        this.showEmail = true;
        this.isClickRightFlag = true;
      }
    },
    // 邮箱校验
    validatorEmail(val) {
      return isEmail(val);
    },
    // 邮箱确定按钮事件
    async sendEmail() {
      if (this.validatorEmail(this.email)) {
        let argArray = [];
        this.invoicedData &&
          this.invoicedData.map((item) => {
            if (item.isChecked) {
              let data = {
                invoiceId: item.invoiceId,
                mail: this.email,
                phone: "13456565656",
                type: 1,
              };
              argArray.push(data);
            }
          });
        const { status, resultData, errorMessage } = await sendInvoice(
          argArray
        );
        if (status === "complete") {
          // 成功后---清除勾选状态：
          this.totalLi = 0;
          this.mailtxtTwo = "批量发送邮箱";
          this.isClickMailBtnTwo = !this.isClickMailBtnTwo;

          this.invoicedData &&
            this.invoicedData.map((item) => {
              item.isChecked = false;
            });
          this.showEmail = false;
          Toast({
            message: "发送成功！",
            icon: "passed",
          });
        } else {
          Toast({
            message: errorMessage,
            icon: "close",
          });
        }
      } else {
        Toast("请输入正确的邮箱地址！");
      }
    },
    // 年份
    getSelectValueInfo(value, index) {
      this.billYear = value;
      this.currentIndex = index;
      this.$forceUpdate();

      this.currentPage = 1;
      this.totalLi = 0;
      if (this.currentTab === "0") {
        this.notInvoicedData = [];
        this.selectNotInvoiced();
        this.$refs.yearOne.toggle();
      } else {
        this.invoicedData = [];
        this.selectInvoiced();
        this.$refs.yearTwo.toggle();
      }
    },
    // 选择年份 - 获取年份(12个月份)
    getYear() {
      const date = new Date();
      const year = date.getFullYear();
      for (let i = year - 11; i <= year; i++) {
        this.yearList.push({
          name: i + "年",
        });
      }
      this.yearList.reverse();
    },
    // 下拉刷新
    onRefresh() {
      this.currentPage = 1;
      this.notInvoicedData = [];
      this.invoicedData = []; // 增加
      this.finished = false;
      this.selectNotInvoiced();
      this.loading = false;
    },
  },
};
</script>

<style lang="less" scoped>
.invoice-page {
  width: 100%;
  .invoice-header {
    background: #ffffff;
    text-align: center;
    height: 60px;
    /deep/.van-tabs {
      margin: 0 auto;
    }
    /deep/ .van-tab {
      padding-top: 10px;
    }
    /deep/.van-tab--active {
      color: #1677ff;
    }
  }
  .invoice-select {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    font-size: 14px;
    background: #ffffff;
    .invoice-setting {
      font-family: PingFangSC-Regular;
      font-size: 12px;
      letter-spacing: 0;
      color: #4466ad;
      span:first-child {
        padding-right: 10px;
      }
      span.current {
        color: rgba(0, 0, 0, 0.25);
      }
    }
    /deep/.van-dropdown-menu__title {
      padding: 0px;
      width: 75px;
      text-align: center;
      color: rgba(0, 0, 0, 0.65);
    }
    /deep/.van-dropdown-menu__bar {
      height: auto;
      background: transparent;
      box-shadow: none;
    }
    /deep/.van-dropdown-menu__title::after {
      border-color: transparent transparent rgba(0, 0, 0, 0.65)
        rgba(0, 0, 0, 0.65);
    }
    /deep/.van-dropdown-menu__item {
      margin-right: 10px;
    }
    // 下拉年份
    .content {
      padding-top: 5px;
      padding-bottom: 10px;
      // li标签
      ul {
        li {
          width: calc(100% / 3 - 17px);
          display: inline-block;
          text-align: center;
          border: 1px solid #eeeeee;
          border-radius: 3px;
          margin-right: 15px;
          margin-top: 15px;
          position: relative;
          overflow: hidden;
          span {
            display: inline-block;
            height: 32px;
            line-height: 32px;
            font-family: PingFangSC-Medium;
            font-size: 14px;
            color: #6e6e6e;
            text-align: center;
          }
          // 选中样式
          .triangle {
            width: 30px;
            height: 30px;
            background: #1677ff;
            position: absolute;
            bottom: -15px;
            right: -15px;
            transform: rotate(45deg);
            i {
              position: absolute;
              bottom: 5px;
              right: 5px;
              display: block;
              width: 18px;
              text-align: center;
              color: #ffffff;
              transform: rotate(-25deg);
            }
          }
          .triangle::after {
            display: inline-block;
            content: "";
            width: 30px;
            height: 35px;
            background: #ffffff;
            transform: rotate(-45deg);
            position: absolute;
            bottom: 18px;
            right: 0px;
            z-index: 20;
          }
        }
        .currentSpan {
          color: #0a79ec;
          background: #cde4ff;
        }
      }
      // 账单筛选
      .right-txt {
        .first-li {
          margin-left: 16px;
          margin-right: 16px;
          margin-bottom: 4px;
          display: flex;
          justify-content: space-between;
          span {
            font-family: PingFangSC-Medium;
            font-size: 16px;
            color: rgba(0, 0, 0, 0.85);
            letter-spacing: 0;
            line-height: 20px;
            font-weight: 600;
          }
        }
        .center-li {
          margin-left: 14px;
          em {
            font-size: 12px;
            padding: 1px 5px;
            font-style: normal;
            font-family: PingFangSC-Regular;
            color: #637fb7;
            letter-spacing: 0;
            line-height: 16px;
            background: #ecf1fe;
            border-radius: 2px;
          }
          p {
            margin: 0px;
            font-size: 14px;
            font-family: PingFangSC-Regular;
            color: rgba(0, 0, 0, 0.65);
            letter-spacing: 0;
            line-height: 22px;
            text-align: left;
          }
        }
      }
      .van-radio {
        margin-top: 15px;
      }
      .van-radio-group {
        margin-left: 16px;
        margin-right: 16px;
        margin-bottom: 15px;
      }
      .bottom-content {
        position: relative;
        // border-top: 1px solid rgba(0,0,0,0.08);
        display: flex;
        justify-content: space-between;
        padding-top: 15px;
        // padding-left: 16px;
        // padding-right: 16px;
        // padding-bottom: 16px;
        .sureBtn {
          width: 79px;
          border-radius: 4px;
          font-size: 14px;
        }
      }
      .bottom-content::after {
        position: absolute;
        box-sizing: border-box;
        content: " ";
        pointer-events: none;
        right: 0px;
        top: 0;
        left: 0px;
        border-top: 1px solid #ebedf0;
        -webkit-transform: scaleY(0.5);
        transform: scaleY(0.5);
      }
    }
  }
  .invoice-content {
    background: #f3f3f3;
    margin-top: 10px;
    margin-bottom: 40px;
    ul {
      margin: 0px 16px;
      .invoice-li {
        background-color: #ffffff;
        border-radius: 8px;
        margin-bottom: 10px;
        position: relative; // 选中样式
        overflow: hidden;
        border: 1px solid transparent;
        .first-li {
          display: flex;
          justify-content: space-between;
          padding: 12px 16px 6px 16px;
          span {
            font-family: PingFangSC-Medium;
            font-size: 16px;
            color: rgba(0, 0, 0, 0.85);
            letter-spacing: 0;
            line-height: 20px;
            font-weight: 600;
          }
        }
        .name-li {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          margin: 0 16px;
          font-size: 14px;
          > span {
            margin-bottom: 6px;
          }
        }
        .name-li-first {
          padding-right: 8px;
        }
        .center-li {
          margin-left: 16px;
          margin-right: 16px;
          em {
            font-size: 12px;
            padding: 1px 5px;
            font-style: normal;
            font-family: PingFangSC-Regular;
            color: #637fb7;
            letter-spacing: 0;
            line-height: 16px;
            background: #ecf1fe;
            border-radius: 2px;
          }
          p {
            margin: 0px;
            padding-top: 8px;
            font-size: 14px;
            font-family: PingFangSC-Regular;
            color: rgba(0, 0, 0, 0.65);
            letter-spacing: 0;
            line-height: 22px;
            text-align: left;
          }
        }
        .last-li {
          display: flex;
          justify-content: space-between;
          margin-left: 16px;
          margin-right: 16px;
          padding-bottom: 11px;
          span {
            font-size: 12px;
            line-height: 32px;
            font-family: PingFangSC-Regular;
            color: rgba(0, 0, 0, 0.45);
            letter-spacing: 0;
          }
          .van-button {
            border-radius: 8px;
            padding-left: 8px;
            padding-right: 8px;
            span {
              font-family: PingFangSC-Regular;
              font-size: 12px;
              color: #4285f4;
              letter-spacing: 0;
              text-align: center;
              line-height: 24px;
            }
          }
          .van-button--default {
            border: 1px solid #4285f4;
          }
        }
      }
      // 选中样式
      .currentLi {
        border: 1px solid #1c74fa;
      }
      .triangle {
        width: 50px;
        height: 50px;
        background: #1677ff;
        position: absolute;
        top: -25px;
        right: -28px;
        transform: rotate(45deg);
        span {
          position: absolute;
          bottom: -4px;
          display: block;
          width: 50px;
          text-align: center;
          color: #ffffff;
          transform: rotate(-25deg);
        }
      }
    }
  }
  .invoice-bottom {
    height: 56px;
    line-height: 56px;
    background: #ffffff;
    // padding: 0px 16px;
    padding: 0px;
    position: fixed;
    bottom: 0px;
    width: 100%;
    .content {
      // display: inline-block;
      em {
        font-family: PingFangSC-Regular;
        font-style: normal;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        letter-spacing: 0;
        float: left;
        padding-left: 18px;
      }
      .btn {
        float: right;
        margin-right: 30px;
        .cancelBtn {
          margin-right: 16px;
          font-family: PingFangSC-Regular;
          font-size: 14px;
          background: #f6f6f6;
          color: #4285f4;
          letter-spacing: 0;
          text-align: center;
          line-height: 24px;
          width: 80px;
          border-radius: 4px;
        }
        .invoiceBtn {
          font-family: PingFangSC-Regular;
          font-size: 14px;
          color: #ffffff;
          background: #4285f4;
          letter-spacing: 0;
          text-align: center;
          line-height: 24px;
          border-radius: 4px;
        }
      }
    }
  }
  .popup-content {
    .title {
      border-bottom: 1px solid #eeeeee;
      padding-top: 12px;
      padding-bottom: 12px;
      p {
        font-family: PingFangSC-Medium;
        font-size: 18px;
        color: #333333;
        text-align: center;
        line-height: 26px;
        margin: 0px;
      }
    }
    .content {
      /deep/.van-field__control {
        height: 45px;
        background-color: #f9f8f8;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        letter-spacing: 0;
        text-indent: 1em;
      }
      .btn {
        margin-left: 16px;
        margin-right: 16px;
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        .cancelBtn {
          width: 125px;
          height: 44px;
          border-radius: 4px;
          background: #f6f6f6;
          color: #4285f4;
        }
        .sendBtn {
          width: 125px;
          height: 44px;
          border-radius: 4px;
        }
      }
      .email-list {
        margin: 16px;
        max-height: 200px;
        // overflow-y: scroll;
        ul {
          li {
            height: 28px;
            line-height: 28px;
          }
        }
      }
    }
  }
}
</style>
